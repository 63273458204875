import UnderConstruction from '../components/UnderConstruction';

const AboutPage = () => {
  return (
    <>
      <UnderConstruction title='About Me' center={false} />
    </>
  );
};

export default AboutPage;

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import GlobalHelper, { ViewType } from '../lib/GlobalHelper';
import NavButton from './NavButton';

const LeftMenu = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (matches && GlobalHelper.viewType === ViewType.withNavBarNoLeft) {
    return <></>;
  }

  return (
    <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column' }}>
      {GlobalHelper.buttons.map((button) => (
        <NavButton key={button.to} to={button.to} buttonText={button.text} type='bigLeft' />
      ))}
    </Box>
  );
};

export default LeftMenu;

import { useLocation } from 'react-router-dom';

import DemoReel from './DemoReel';
import ShortFilmsPreview from './ShortFilmsPreview';
import UnderConstruction from './UnderConstruction';

const RightContent = () => {
  const location = useLocation();

  if (location.pathname === '/') {
    return <DemoReel />;
  }
  if (location.pathname === '/gallery') {
    return <UnderConstruction title='Gallery' center={true} />;
  }
  if (location.pathname === '/shortfilms') {
    return <ShortFilmsPreview />;
  }
  if (location.pathname === '/about') {
    return <UnderConstruction title='About Me' center={true} />;
  }

  return <></>;
};

export default RightContent;

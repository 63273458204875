import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import { ShortFilm } from '../models/ShortFilm';
import { useStore } from '../stores/Store';
import Loading from './Loading';

const slideDelay = 5000;
const boxStyle = { width: '100%', maxWidth: '768px', maxHeight: '432px', margin: 'auto' };

const ShortFilmsPreview = () => {
  const { mainStore } = useStore();
  const { loading, loadShortFilms, shortFilms } = mainStore;
  // const [swiper, setSwiper] = useState<any>(undefined);
  const [showInfo, setShowInfo] = useState(true);
  const [slideTimeout, setSlideTimeout] = useState(1000);

  useEffect(() => {
    loadShortFilms().then(() => console.log('LOADED'));
  }, [loadShortFilms]);

  const handleSlideChange = () => {
    console.log('handleSlideChange');
    setSlideTimeout(1);
    setShowInfo(false);
    setTimeout(() => {
      setSlideTimeout(1000);
      setShowInfo(true);
    }, 1000);
  };

  const handlePlay = (film: ShortFilm) => {
    console.log('Need to play ' + film.title);
  };

  if (loading) {
    console.log('loading');
    return (
      <Box sx={boxStyle}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={boxStyle}
          onMouseLeave={() => {
            console.log('mouse left');
            // swiper.autoplay?.start();
          }}
        >
          <Swiper
            modules={[Navigation, Pagination, Autoplay, EffectFade]}
            spaceBetween={30}
            slidesPerView={1}
            speed={800}
            effect='fade'
            navigation={{ enabled: true, hideOnClick: true }}
            autoplay={{ delay: slideDelay, pauseOnMouseEnter: false }}
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            onSlideChange={handleSlideChange}
            onSwiper={(swiper) => console.log('setSwiper(swiper)')}
          >
            {shortFilms.map((film) => (
              <SwiperSlide key={film.id}>
                <img src={film.imageUrl} alt={film.title} width='768px' style={{ maxWidth: '100%', zIndex: 4 }}></img>
                <Box
                  textAlign='center'
                  sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: 80,
                    width: '100%',
                    zIndex: 10,
                    color: 'white',
                    // border: '1px solid white',
                  }}
                >
                  <Slide direction='down' in={showInfo} mountOnEnter unmountOnExit timeout={slideTimeout}>
                    <Typography variant='h5' sx={{ textShadow: '2px 2px 5px #ba68c8' }}>
                      {film.title}
                    </Typography>
                  </Slide>
                  <Slide direction='down' in={showInfo} mountOnEnter unmountOnExit timeout={slideTimeout}>
                    <Button variant='outlined' onClick={() => handlePlay(film)}>
                      Play
                    </Button>
                  </Slide>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default observer(ShortFilmsPreview);

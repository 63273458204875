const Separator = () => {
  return (
    <img
      src='/images/divider.png'
      alt='divider'
      width='100%'
      height='20px'
      style={{ marginTop: '10px', marginBottom: '10px' }}
    ></img>
  );
};

export default Separator;

import { ShortFilm } from '../models/ShortFilm';

interface FetchData {
  json?: any;
  error?: Error;
}

export default class Fetch {
  private static json = async (url: string): Promise<FetchData> => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
      return { json: await response.json() };
    } catch (error) {
      console.log(error);
      return { error: error as Error };
    }
  };

  static shortFilms = async (): Promise<ShortFilm[]> => {
    try {
      const result = await this.json('/data/short-films/short-films.json');
      if (!!result.error) {
        throw result.error;
      }
      if (!!result.json) {
        const shortFilms: ShortFilm[] = result.json as ShortFilm[];
        if (!!shortFilms) {
          return shortFilms;
        }
      }
      throw new Error('Unknown error fetching short films');
    } catch (error) {
      console.log(error);
      // toast.error(error);
      return [];
    }
  };
}

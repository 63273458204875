import { createContext, useContext } from 'react';

import MainStore from './MainStore';
import UserStore from './UserStore';

interface Store {
  userStore: UserStore;
  mainStore: MainStore;
}

export const store: Store = {
  userStore: new UserStore(),
  mainStore: new MainStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};

import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { User, UserForLogin } from '../models/User';

export default class UserStore {
  user?: User = undefined;
  token: string | null = window.localStorage.getItem('jwt');

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem('jwt', token);
        } else {
          window.localStorage.removeItem('jwt');
        }
      }
    );
  }

  setToken = (token: string | null) => {
    this.token = token;
  };

  get isLoggedIn() {
    return !!this.user;
  }

  get isDarshi() {
    return this.isLoggedIn && this.user!.email?.toLowerCase() === 'darshaniw2016@gmail.com';
  }

  login = async (creds: UserForLogin, navigate?: NavigateFunction) => {
    try {
      console.log('calling login on agent');
      const user: User = { email: creds.email, token: 'dummy' }; // await agent.Account.login(creds);
      if (!user) {
        console.log('user was null');
        return false;
      }
      console.log(user);
      this.setToken(user.token);
      runInAction(() => {
        this.user = user;
      });
      if (navigate) {
        navigate('/');
      }
      return true;
    } catch (error: any) {
      // throw error;
      console.log('login error');
      console.log(error);
      return false;
    }
  };

  logout = (navigate?: NavigateFunction) => {
    console.log('logging out');
    this.setToken(null);
    this.user = undefined;
    if (navigate) {
      navigate('/');
    }
  };

  loadUser = async () => {
    try {
      const user: User = { email: 'asanga@weerapura.com', token: 'dummy' }; // await agent.Account.current();
      runInAction(() => (this.user = user));
    } catch (error: any) {
      console.log(error);
    }
  };
}

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const Loading = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#eee',
        borderRadius: '10px',
        boxShadow:
          'rgba(17, 17, 26, 0.1) 0px 4px 16px 6px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
      }}
    >
      <Box>
        <Typography variant='h4'>Loading...</Typography>
        <CircularProgress color='primary' />
      </Box>
    </Box>
  );
};

export default Loading;

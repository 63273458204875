import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Loading from '../components/Loading';
import Separator from '../components/Separator';
import VideoPlayer from '../components/VideoPlayer';
import { useStore } from '../stores/Store';

const slideDelay = 5000;

const ShortFilmsPage = () => {
  const { mainStore } = useStore();
  const { loading, loadShortFilms, shortFilms } = mainStore;

  useEffect(() => {
    loadShortFilms();
  }, [loadShortFilms]);

  const formatDate = (dateString: string) => {
    const date = parse(dateString, 'yyyy-MM-dd', new Date());
    console.log(date);
    return format(date, 'MMMM yyyy');
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {shortFilms.map((film, index) => (
        <Box key={film.id} sx={{ mb: 2 }}>
          <Typography variant='h2' color='primary' sx={{ fontFamily: "'Fredoka One', cursive", textAlign: 'center' }}>
            {film.title}
          </Typography>
          <Typography variant='h4' color='black' sx={{ fontFamily: "'Fredoka One', cursive", textAlign: 'center' }}>
            {formatDate(film.date)} - {film.length} - {film.type}
          </Typography>
          <Grid container>
            <Grid item md xs={12}>
              <Box>
                <VideoPlayer url={film.url} />
              </Box>
            </Grid>
            <Grid item md='auto' xs={12}>
              <Box sx={{ maxHeight: '280px', height: '280px', p: 1 }}>
                {film.posters.length === 1 && <img src={film.posters[0]} alt={film.title} height='100%'></img>}
                {film.posters.length > 1 && (
                  <Swiper
                    modules={[Pagination, Autoplay]}
                    spaceBetween={30}
                    slidesPerView={1}
                    speed={800}
                    // navigation={{ enabled: false }}
                    autoplay={{ delay: slideDelay, pauseOnMouseEnter: false }}
                    pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    onSlideChange={() => console.log('onSlideChange')}
                    onSwiper={(swiper) => console.log('setSwiper(swiper)')}
                    style={{ height: '280px', width: ' 200px' }}
                  >
                    {film.posters.map((url) => (
                      <SwiperSlide key={url}>
                        <img src={url} alt={film.title} height='280px' width=' 200px'></img>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </Box>
            </Grid>
          </Grid>
          {index < shortFilms.length - 1 && <Separator />}
        </Box>
      ))}
    </>
  );
};

export default observer(ShortFilmsPage);

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import GlobalHelper, { ViewType } from '../lib/GlobalHelper';
import NavButton from './NavButton';

const drawerWidth = 200;
const title = 'Esandi';

const TopNavBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const { userStore } = useStore();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (path: string) => {
    setAnchorEl(null);
    if (path === '/logout') {
      // userStore.logout(navigate);
    } else {
      navigate(path);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        {title}
      </Typography>
      <Divider />
      <List>
        {GlobalHelper.buttons.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href={item.to}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        {GlobalHelper.isInDevelopment && (
          <span>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} href={'/logout'}>
                <ListItemText primary='Logout' />
              </ListItemButton>
            </ListItem>
          </span>
        )}
      </List>
    </Box>
  );

  if (GlobalHelper.viewType !== ViewType.withNavBar && GlobalHelper.viewType !== ViewType.withNavBarNoLeft) {
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component='nav'>
        <Container maxWidth='lg'>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
              {title}
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {GlobalHelper.buttons.map((item) => (
                <NavButton
                  key={item.to}
                  to={item.to}
                  buttonText={item.text}
                  type='navbar'
                  buttonProps={{ variant: 'text', sx: { color: '#fff' } }}
                />
                // <Button key={item.Name} variant='text' sx={{ color: '#fff' }} onClick={() => navigate(item.Path)}>
                //   {item.Name}
                // </Button>
              ))}
              {GlobalHelper.isInDevelopment && (
                <span>
                  <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleMenu}
                    color='inherit'
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleClose('/logout')}>Logout</MenuItem>
                  </Menu>
                </span>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component='nav'>
        <Drawer
          // container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component='main' sx={{ p: 0 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};

export default TopNavBar;

export enum ViewType {
  first,
  withNavBar,
  withNavBarNoLeft,
}

export default class GlobalHelper {
  static get viewType() {
    return ViewType.withNavBarNoLeft;
  }

  static get buttons() {
    return [
      {
        text: 'Home',
        to: '/',
      },
      {
        text: 'Short Films',
        to: '/shortfilms',
      },
      {
        text: 'Gallery',
        to: '/gallery',
      },
      {
        text: 'About',
        to: '/about',
      },
    ];
  }

  static get isInDevelopment() {
    return true;
  }
}

import './App.css';

import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

import LeftMenu from './components/LeftMenu';
import MainTitle from './components/MainTitle';
import RightContent from './components/RightContent';
import Separator from './components/Separator';
import TopNavBar from './components/TopNavBar';
import GlobalHelper, { ViewType } from './lib/GlobalHelper';
import AboutPage from './pages/AboutPage';
import GalleryPage from './pages/GalleryPage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import ShortFilmsPage from './pages/ShortFilmsPage';

function App() {
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const haveLeft = !matches || GlobalHelper.viewType !== ViewType.withNavBarNoLeft;

  return (
    <div className='App'>
      <CssBaseline />
      <TopNavBar />
      <Container maxWidth='lg'>
        {haveLeft && (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm='auto'>
              <MainTitle />
              <LeftMenu />
            </Grid>
            <Grid item xs={12} sm>
              <RightContent />
            </Grid>
          </Grid>
        )}
        {!haveLeft && (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <MainTitle />
            </Grid>
            <Grid item xs={12}>
              <RightContent />
            </Grid>
          </Grid>
        )}
        {location.pathname !== '/' && <Separator />}
        <Box sx={{ px: 2, pt: 1 }}>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='shortfilms' element={<ShortFilmsPage />} />
            <Route path='gallery' element={<GalleryPage />} />
            <Route path='about' element={<AboutPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Box>
      </Container>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;

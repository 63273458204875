import { makeAutoObservable, runInAction } from 'mobx';

import Fetch from '../lib/Fetch';
import { ShortFilm } from '../models/ShortFilm';

export default class MainStore {
  loading = false;
  shortFilms: ShortFilm[] = [];
  shortFilmsLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadShortFilms = async (reload: boolean = false) => {
    if ((reload || !this.shortFilmsLoaded) && !this.loading) {
      this.loading = true;
      try {
        const shortFilms = (await Fetch.shortFilms()).filter((x) => x.title.length > 0);
        console.log('shortFilms', shortFilms);
        runInAction(() => {
          this.shortFilms = shortFilms;
          this.shortFilmsLoaded = true;
        });
      } catch (error) {
        console.log(error);
        // toast.error(error);
      } finally {
        runInAction(() => (this.loading = false));
      }
    } else {
      const reason = this.loading ? 'Currently loading' : 'Already loaded';
      console.log('loadShortFilms - NOT loading - ' + reason);
    }
  };
}

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const DemoReel = () => {
  const styles = {
    boxContainer: {
      backgroundImage: `url(${'/images/squiggly.jpg'})`,
      backgroundSize: 'cover',
    },
  };

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%', borderRadius: '8px' }} style={styles.boxContainer}>
      <Box sx={{ display: 'inline-block', alignSelf: 'flex-end', width: '100%', p: 1 }}>
        <video
          width='100%'
          height='auto'
          controls
          style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
        >
          <source src='/videos/BathBody-FirstYear.m4v' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs='auto'>
              <Box
                component={'div'}
                sx={{
                  background: 'linear-gradient(to right, #e1bee7, #f3e5f5)',
                  borderRadius: '50em',
                  boxShadow:
                    'rgba(17, 17, 26, 0.1) 0px 4px 16px 6px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
                }}
              >
                <Typography
                  variant='h4'
                  sx={{
                    px: 2,
                    fontFamily: "'Gloria Hallelujah', cursive",
                    fontWeight: '800',
                    // background: 'white',
                    borderRadius: '50em',
                  }}
                >
                  Demo Reel
                </Typography>
              </Box>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DemoReel;

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const MainTitle = () => {
  return (
    <>
      <Grid container>
        <Grid item>
          <Typography
            variant='h3'
            sx={{ fontFamily: "'Permanent Marker', cursive", textShadow: '2px 2px 5px #ba68c8', mr: 1 }}
          >
            Esandi
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='h3'
            sx={{ fontFamily: "'Permanent Marker', cursive", textShadow: '2px 2px 5px #ba68c8' }}
          >
            Amarakoon
          </Typography>
        </Grid>
      </Grid>
      <Typography variant='h5' sx={{ fontFamily: "'Fredoka One', cursive", mt: 1, textTransform: 'uppercase' }}>
        Animation and Art
      </Typography>
    </>
  );
};

export default MainTitle;

import { NavLink, useLocation } from 'react-router-dom';

import Button, { ButtonTypeMap } from '@mui/material/Button';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

interface Props {
  buttonText: string;
  to: string;
  type: 'navbar' | 'bigLeft';
  buttonProps?: DefaultComponentProps<ButtonTypeMap>;
}

const NavButton = ({ to, buttonText, type, buttonProps }: Props) => {
  const location = useLocation();

  return (
    <NavLink to={to} style={{ textDecoration: 'none' }}>
      {type === 'bigLeft' && (
        <Button
          variant={location.pathname !== to ? 'contained' : 'outlined'}
          sx={{ width: '100%', mt: 2, fontFamily: "'Fredoka One', cursive", fontSize: '20pt' }}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      )}
      {type === 'navbar' && <Button {...buttonProps}>{buttonText}</Button>}
    </NavLink>
  );
};

export default NavButton;

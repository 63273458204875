import UnderConstruction from '../components/UnderConstruction';

const GalleryPage = () => {
  return (
    <>
      <UnderConstruction title='Gallery' center={false} />
    </>
  );
};

export default GalleryPage;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  center: boolean;
}

const UnderConstruction = ({ title, center }: Props) => {
  const isRightContent = center;
  const style = isRightContent
    ? { width: '100%', height: '432px', maxWidth: '768px', maxHeight: '432px', margin: 'auto' }
    : {};

  return (
    <Box sx={{ width: '100%', textAlign: center ? 'center' : 'left', alignContent: 'center' }}>
      <Box style={style}>
        <Typography variant='h3' sx={{ fontFamily: "'Fredoka One', cursive" }}>
          {title}
        </Typography>
        <Typography variant='h5' sx={{ fontFamily: "'Permanent Marker', cursive" }}>
          Under Construction
        </Typography>
      </Box>
    </Box>
  );
};

export default UnderConstruction;
